import { Modal, Button } from "react-bootstrap";
 
interface deleteConfirmationModel {
    showModal: boolean,
    hideModal: () => void,
    confirmModal: (id : number) => void,
    id: number,
    message: string
}

const DeleteConfirmation = ({ showModal, hideModal, confirmModal, id, message } : deleteConfirmationModel) => {
    return (
        <Modal show={showModal} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Verwijderen</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={hideModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={() => confirmModal(id) }>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    )
}
 
export default DeleteConfirmation;