import moment from "moment";
import axiosInstance from "../../config/axios/AxiosInstance";
import { useCallback, useEffect, useState } from "react";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";

export const DweilpauzeCard = () => {
    const authHeader = useAuthHeader();
    const [data, setData] = useState({
        datum: '',
        dweiltijden: []
    });

    const handleGet = useCallback(() => {
        const vandaag = moment().format("YYYY-MM-DD");
        axiosInstance.get(`/api/dweilpauze?type=group&datum=${vandaag}`, { headers: { Authorization: authHeader } })
            .then(res => {
                setData(res.data[0])
            })
            .catch(err => console.log(err));
    }, [authHeader]);

    useEffect(() => {
        handleGet()
    }, [handleGet])

    return (
        <div className="card h-100">
            <div className="card-header">
                Dweilpauzes vandaag
            </div>
            <div className="card-body">
                <ul>
                    {
                        data && data?.dweiltijden?.length > 0 && data.dweiltijden?.map((d, i) => (
                            <li key={i}>{d}</li>
                        ))
                    }
                </ul>
                {
                    (data?.dweiltijden?.length === 0 || !data) && <span>Geen dweilpauzes vandaag</span>
                }
            </div>
        </div>
    )
}

export default DweilpauzeCard