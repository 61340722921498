import moment from "moment"
import axiosInstance from "../../config/axios/AxiosInstance"
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader"
import { useCallback, useEffect, useState } from "react";


export const OpeningstijdenCard = () => {
    const authHeader = useAuthHeader();
    const [data, setData] = useState({
        status: '',
        data: {
            openingstijd: null,
            sluitingstijd: null,
            opmerking: null
        }
    });

    const handleGet = useCallback(() => {
        const vandaag = moment().format("YYYY-MM-DD");
        axiosInstance.get(`/api/openingstijd/checkopen?datum=${vandaag}`, { headers: { Authorization: authHeader } })
            .then(res => setData(res.data))
            .catch(err => console.log(err));
    }, [authHeader])

    useEffect(() => {
        handleGet();
    }, [handleGet])

    return (
        <div className="card h-100">
            <div className="card-header">
                Openingstijden
            </div>
            <div className="card-body">
                {data.status === "Open" && <h2 style={{color: "green"}}>{data.status}</h2>}
                {data.status === "Gesloten" && <h2 style={{color: "red"}}>{data.status}</h2>}
                {data.status === "Open" && <p>We zijn geopend van {moment(data.data.openingstijd).format("HH:mm")} tot {moment(data.data.sluitingstijd).format("HH:mm")}</p>}
            </div>
        </div>
    )
}

export default OpeningstijdenCard