import axios from "axios";
import moment from 'moment'
import { FormEvent, useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import Layout from "../layout/Layout";
import { Alert } from "react-bootstrap";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";

type Locatie = {
    id: number,
    naam: string
}

const Create = () => {
    const authHeader = useAuthHeader();
    let today = moment().format('YYYY-MM-DDTHH:mm');
    const [locaties, setLocaties] = useState<Locatie[]>([]);
    const [values, setValues] = useState({
        locatieId: '',
        gesloten: false,
        openingstijd: today,
        sluitingstijd: today,
        opmerking: "",
        drukte: ""
    });

    useEffect(() => {
        axios.get(process.env.REACT_APP_SERVER_URL + "/api/locatie", { headers: { Authorization: authHeader } })
            .then(res => setLocaties(res.data))
            .catch(err => { 
                console.log(err);
                setErrorMessage(err.response.data.message);
            });
    }, []);

    // handle errors
    const [errorMessage, setErrorMessage] = useState('');

    const navigate = useNavigate();
    const handleSubmit = (event : FormEvent) => {
        event.preventDefault();
        axios.post(process.env.REACT_APP_SERVER_URL + "/api/openingstijd", values, { headers: { Authorization: authHeader } })
        .then(res => {
            console.log(res);
            navigate("/openingstijd");
        })
        .catch(err => {
            console.log(err);
            setErrorMessage(err.response.data.message);
        });
    };

    return (
        <Layout>
            <div>
                <h1>Openingstijd toevoegen</h1>
                {errorMessage && (
                    <Alert variant="danger"> {errorMessage} </Alert>
                )}
                <form onSubmit={handleSubmit}>
                <div className="mb-2">
                        <label htmlFor="locatie">Locatie: </label>
                        <select 
                            name="locatie" 
                            className="form-control" 
                            value={values.locatieId}
                            onChange={e => setValues({...values, locatieId: e.target.value})}
                            required
                        >
                            <option value="" disabled>Kies een locatie</option>
                            {locaties.map(locatie => (
                                <option key={locatie.id} value={locatie.id}>{locatie.naam}</option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-2">
                        <label htmlFor="openingstijd">Openingstijd: </label>
                        <input type="datetime-local" name="openingstijd" className="form-control" defaultValue={today} onChange={e => setValues({...values, openingstijd: e.target.value})}/>
                    </div>
                    <div className="mb-2">
                        <label htmlFor="sluitingstijd">Sluitingstijd: </label>
                        <input type="datetime-local" name="sluitingstijd" className="form-control" defaultValue={today} onChange={e => setValues({...values, sluitingstijd: e.target.value})}/>
                    </div>
                    <div className="mb-2">
                        <label htmlFor="baan">Druktemeter: </label>
                        <select 
                            name="baan"
                            className="form-control" 
                            value={values.drukte}
                            onChange={e => setValues({...values, drukte: e.target.value})}
                            required
                        >
                            <option value="" disabled>Kies een verwachte drukte</option>   
                            <option value="not_busy">Rustig</option>
                            <option value="medium">Gemiddeld</option>
                            <option value="busy">Druk</option>
                        </select>
                    </div>
                    <div className="mb-2">
                        <label htmlFor="opmerking">Opmerking: </label>
                        <input type="text" name="opmerking" className="form-control" onChange={e => setValues({...values, opmerking: e.target.value})}/>
                    </div>
                    <div className="mb-2">
                        <label htmlFor="opmerking">Gesloten: </label>
                        <input type="checkbox" name="gesloten" className="m-2" onChange={e => setValues({...values, gesloten: e.target.checked})}/>
                    </div>
                    <button type="submit" className="btn btn-success mt-2">Opslaan</button>
                    <Link to="/openingstijd" className="btn btn-primary ms-3 mt-2">Terug</Link>
                </form>
            </div>
        </Layout>
    )
}

export default Create