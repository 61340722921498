import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { Nav } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export const Logout = () => {
    const signOut = useSignOut()
    const navigate = useNavigate()

    const handleLogout = () => {
      signOut()
      navigate('/login')
    }

    return (
      <Nav.Link onClick={handleLogout}>Uitloggen</Nav.Link>
    )
}