import DweilpauzeCard from "./components/dashboard/DweilpauzeCard"
import NorenuurCard from "./components/dashboard/NorenuurCard"
import OpeningstijdenCard from "./components/dashboard/OpeningstijdenCard"
import Layout from "./components/layout/Layout"

const Dashboard = () => {
    return (
        <Layout>
            <div>
                <div className="row">
                    <div className="col">
                        <OpeningstijdenCard/>
                    </div>
                    <div className="col">
                       <DweilpauzeCard/>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col">
                        <NorenuurCard/>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Dashboard