import { useCallback, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { Table, Alert } from 'react-bootstrap';
import Layout from "../layout/Layout";
import DeleteConfirmation from "../modals/deleteConfirmation";
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';
import axiosInstance from "../../config/axios/AxiosInstance";

interface Locatie {
    id: number,
    naam: string,
}

const IndexLocatie = () => {
    const authHeader = useAuthHeader()
    const [data, setData] = useState<Locatie[]>([])

    // handle errors
    const [error, setError] = useState(null)
    const errorMessageBox = error 
        ? <Alert variant="danger">{error}</Alert>
        : '';

    //get records from locaties
    const handleGet = useCallback(() => {
        axiosInstance.get(`/api/locatie`, { headers: { Authorization: authHeader } })
        .then(res => setData(res.data))
        .catch(err => console.log(err));
    },[authHeader]);

    useEffect(() => {
        handleGet();
    }, [handleGet])

    //delete handle with modal
    const [deleteId, setDeleteId] = useState(0);
    const [deleteModalshow, setDeleteModalShow] = useState(false);
    const handleHideDeleteModal = () => setDeleteModalShow(false);
    const handleShowDeleteModal = (id : number) => {   
        setDeleteId(id); 
        setDeleteModalShow(true);
      };

    const handleDelete = (id : number) => {
        axiosInstance.delete('/api/locatie/' + id, { headers: { Authorization: authHeader } })
        .then(res => {
            handleGet();
            handleHideDeleteModal();
        }).catch(err => {
            console.log(err);
            setError(err);
        })
    }

    return (
        <Layout>
            <div className="mb-4">
                <div className="row">
                    <div className="mb-2 mt-2">
                        <div className="row">
                            <div className="col">
                                <h1 className="float-start">Locaties</h1>
                                {errorMessageBox}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <Link to="/locatie/create" className="btn btn-success float-end m-1">+ Locatie toevoegen</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <Table className="mt-4" striped bordered hover>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Naam</th>
                            <th>Acties</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.map((d, i) => (
                                <tr key={i}>
                                    <td>{d.id}</td>
                                    <td>{d.naam}</td>
                                    <td>
                                        <div className="float-end">
                                            <Link to={"/locatie/update/" + d.id} className="btn btn-sm btn-success me-2">Bewerken</Link>
                                            <button className="btn btn-sm btn-danger" onClick={e => handleShowDeleteModal(d.id)}>Verwijderen</button>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </div>
            <DeleteConfirmation showModal={deleteModalshow} confirmModal={handleDelete} hideModal={handleHideDeleteModal} id={deleteId} message="Weet je zeker dat je deze locatie wilt verwijderen?"  />
        </Layout>
    )
}

export default IndexLocatie