import axios from "axios";
import { FormEvent, useEffect, useState } from "react";
import { Link, useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import Layout from "../layout/Layout";
import { Alert } from "react-bootstrap";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";

const Update = () => {
    const { id } = useParams();
    const authHeader = useAuthHeader();

    const [values, setValues] = useState({
        gesloten: false,
        openingstijd: '',
        sluitingstijd: '',
        opmerking: '',
        drukte: ''
    })

    // handle errors
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        axios.get(process.env.REACT_APP_SERVER_URL + "/api/openingstijd/" + id, { headers: { Authorization: authHeader } })
            .then(res => setValues(res.data))
            .catch(err => { 
                console.log(err);
                setErrorMessage(err.response.data.message);
            });
    }, [authHeader, id])

    const navigate = useNavigate();
    const handleUpdate = (event : FormEvent) => {
        event.preventDefault();
        axios.put(process.env.REACT_APP_SERVER_URL + "/api/openingstijd/" + id, values,  { headers: { Authorization: authHeader } })
        .then(res => {
            console.log(res);
            navigate("/openingstijd");
        })
        .catch(err => {
            console.log(err);
            setErrorMessage(err.response.data.message);
        });
    };

    return (
        <Layout>
            <div>
                <h1>Openingstijd bewerken</h1>
                {errorMessage && (
                    <Alert variant="danger"> {errorMessage} </Alert>
                )}
                <form onSubmit={handleUpdate}>
                    <div className="mb-2">
                        <label htmlFor="openingstijd">Openingstijd: </label>
                        <input type="datetime-local" name="openingstijd" className="form-control" value={moment(values.openingstijd).format('YYYY-MM-DDTHH:mm')} onChange={e => setValues({...values, openingstijd: e.target.value})}/>
                    </div>
                    <div className="mb-2">
                        <label htmlFor="sluitingstijd">Sluitingstijd: </label>
                        <input type="datetime-local" name="sluitingstijd" className="form-control" value={moment(values.sluitingstijd).format('YYYY-MM-DDTHH:mm')} onChange={e => setValues({...values, sluitingstijd: e.target.value})}/>
                    </div>
                    <div className="mb-2">
                        <label htmlFor="opmerking">Opmerking: </label>
                        <input type="text" name="opmerking" className="form-control" value={values.opmerking} onChange={e => setValues({...values, opmerking: e.target.value})}/>
                    </div>
                    <div className="mb-2">
                        <label htmlFor="baan">Druktemeter: </label>
                        <select 
                            name="baan"
                            className="form-control" 
                            value={values.drukte}
                            onChange={e => setValues({...values, drukte: e.target.value})}
                            required
                        >
                            <option value="" disabled>Kies een verwachte drukte</option>   
                            <option value="not_busy">Rustig</option>
                            <option value="medium">Gemiddeld</option>
                            <option value="busy">Druk</option>
                        </select>
                    </div>
                    <div className="mb-2">
                        <label htmlFor="opmerking">Gesloten: </label>
                        <input type="checkbox" name="gesloten" className="m-2" checked={values.gesloten} onChange={e => setValues({...values, gesloten: e.target.checked})}/>
                    </div>
                    <button type="submit" className="btn btn-success mt-2">Opslaan</button>
                    <Link to="/openingstijd" className="btn btn-primary ms-3 mt-2">Terug</Link>
                </form>
            </div>
        </Layout>
    )
}

export default Update