import LoginLayout from "./layout/LoginLayout"
import leftImage from "../content/login/schaatsbaan-logo-donkerpaars.png"
import { FormEvent, useState } from "react"
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Alert } from "react-bootstrap";
import useSignIn from 'react-auth-kit/hooks/useSignIn';

export const Login = () => {
    const [values, setValues] = useState({
        email: "",
        password: ""
    });

    // handle errors
    const [errorMessage, setErrorMessage] = useState('');

    const signIn = useSignIn();
    const navigate = useNavigate();
    const handleSubmit = (event : FormEvent) => {
        try {
            event.preventDefault();
            axios.post(process.env.REACT_APP_SERVER_URL + "/api/login", values)
            .then(res => {
                console.log(res);
                if (res.status === 200 && signIn({
                    auth: {
                        token: res.data.accessToken,
                        type: 'Bearer'
                    },
                    refresh: res.data.refreshToken,
                    userState: {
                        id: res.data.id,
                        email: res.data.email
                    }
                })){
                    navigate("/")
                }
                else {
                    setErrorMessage("Inloggen mislukt!");
                }
            })
            .catch(err => {
                console.log(err);
                setErrorMessage(err.response ? err.response.data.message : "API Server niet bereikbaar");
            });
        } catch (err) {
            console.log(err);
            setErrorMessage("Inloggen is mislukt!");
        }
    };

    return (
        <LoginLayout>
            <div className="col-md-6 d-none d-md-flex bg-image"></div>

            <div className="col-md-6 bg-light">
                <div className="login d-flex align-items-center py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 col-xl-7 mx-auto">
                                <h3 className="display-4"><img src={leftImage} alt="Schaatsbaan Logo" height="100" className="mb-2" /></h3>
                                <h3>inloggen Tijden App</h3>
                                <p className="text-muted mb-4">Onderstaand kun je inloggen door je e-mailadres en wachtwoord in te vullen.</p>
                                <div className="mb-4">
                                    {errorMessage && (
                                        <Alert variant="danger"> {errorMessage} </Alert>
                                    )}
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group mb-3">
                                            <input type="text" name="email" className="form-control rounded-pill border-0 shadow-sm px-4" placeholder="E-mailadres" required onChange={e => setValues({...values, email: e.target.value})}/>
                                        </div>
                                        <div className="form-group mb-3">
                                            <input type="password" name="sluitingstijd" className="form-control rounded-pill border-0 shadow-sm px-4" placeholder="Wachtwoord" required onChange={e => setValues({...values, password: e.target.value})}/>
                                        </div>
                                        <div className="d-grid">
                                            <button type="submit" className="btn btn-primary btn-primary-login btn-block mb-2 rounded-pill shadow-sm">Inloggen</button>
                                        </div>
                                    </form>
                                </div>
                                <p className="text-muted mb-4"><b>Wachtwoord vergeten?</b> <br />Vraag hier een nieuw wachtwoord aan.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LoginLayout>
    )
}

export default Login