import React from 'react';
import "../../content/login/index.css";

type Props = {
  children: React.ReactNode,
};

const LoginLayout : React.FC<Props> = ({
  children
}) => (
    <div className="container-fluid">
        <div className="row no-gutter">
            {children}
        </div>
    </div>
  );

export default LoginLayout;
