import { useCallback, useEffect, useState } from "react";
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Table, Alert } from 'react-bootstrap';
import Layout from "../layout/Layout";
import DeleteConfirmation from "../modals/deleteConfirmation";
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';
import axiosInstance from "../../config/axios/AxiosInstance";

interface Dweilpauze {
    id: number,
    datumtijd: string,
    duur: number
    locatie: Locatie
}

interface Locatie {
    id?: number;
    naam: string;
}

const IndexNorenuur = () => {
    const authHeader = useAuthHeader()
    const [data, setData] = useState<Dweilpauze[]>([]);
    const [selectedLocatieId, setSelectedLocatieId] = useState<string>('');
    const [locaties, setLocaties] = useState<Locatie[]>([]);

    // handle errors
    const [error, setError] = useState(null)
    const errorMessageBox = error 
        ? <Alert variant="danger">{error}</Alert>
        : '';

    //get records from openingstijd
    const handleGet = useCallback((date? : string) => {
        axiosInstance.get(`/api/norenuur?datum=${date ?? ''}&locatieId=${selectedLocatieId}`, { headers: { Authorization: authHeader } })
        .then(res => setData(res.data))
        .catch(err => console.log(err));
    }, [authHeader, selectedLocatieId]);

    // Fetch locations
    const getLocaties = useCallback(() => {
        axiosInstance.get(`/api/locatie`, { headers: { Authorization: authHeader } })
        .then(res => setLocaties(res.data))
        .catch(err => console.log(err));
    }, [authHeader]);

    useEffect(() => {
        handleGet();
        getLocaties();
    }, [selectedLocatieId, handleGet, getLocaties]);

    //delete handle with modal
    const [deleteId, setDeleteId] = useState(0);
    const [deleteModalshow, setDeleteModalShow] = useState(false);
    const handleHideDeleteModal = () => setDeleteModalShow(false);
    const handleShowDeleteModal = (id : number) => {   
        setDeleteId(id); 
        setDeleteModalShow(true);
      };

    const handleDelete = (id : number) => {
        axiosInstance.delete('/api/norenuur/' + id, { headers: { Authorization: authHeader } })
        .then(res => {
            handleGet();
            handleHideDeleteModal();
        }).catch(err => {
            console.log(err);
            setError(err);
        })
    }

    // search handle
    const handleSearch = (date : string) => {
        const datum = moment(date);
        if (datum.isValid()) {
            handleGet(datum.format("YYYY-MM-DD"));
        }
        else {
            handleGet();
        }
    }

    // handle location filter
    const handleSelectLocation = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedLocatieId(e.target.value);
    }

    return (
        <Layout>
            <div className="mb-4">
                <div className="row">
                    <div className="mb-2 mt-2">
                        <div className="row">
                            <div className="col">
                                <h1 className="float-start">Norenuurtjes</h1>
                                {errorMessageBox}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <form className="form-floating">
                                    <input type="date" name="search" className="form-control" onChange={e => handleSearch(e.target.value)}/>
                                    <label htmlFor="search">Datum: </label>
                                </form>
                                <div className="mt-2">
                                    <label htmlFor="locatie">Locatie: </label>
                                    <select 
                                        name="locatie" 
                                        className="form-control" 
                                        value={selectedLocatieId}
                                        onChange={handleSelectLocation}
                                    >
                                        <option value="">Kies een locatie</option>
                                        {locaties.map(locatie => (
                                            <option key={locatie.id} value={locatie.id}>{locatie.naam}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-6 text-right">
                                <Link to="/norenuur/create" className="btn btn-success float-end m-1">+ Norenuurtje toevoegen</Link>
                                <Link to="/norenuur/generator" className="btn btn-primary float-end m-1">+ Norenuurtje genereren</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <Table className="mt-4" striped bordered hover>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Locatie</th>
                            <th>Datum en tijd</th>
                            <th>Duur</th>
                            <th>Acties</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.map((d, i) => (
                                <tr key={i}>
                                    <td>{d.id}</td>
                                    <td>{d.locatie?.naam}</td>
                                    <td>{moment(d.datumtijd).format('DD-MM-YYYY HH:mm')}</td>
                                    <td>
                                        {d.duur}
                                    </td>
                                    <td>
                                        <div className="float-end">
                                            <Link to={"/norenuur/update/" + d.id} className="btn btn-sm btn-success me-2">Bewerken</Link>
                                            <button className="btn btn-sm btn-danger" onClick={e => handleShowDeleteModal(d.id)}>Verwijderen</button>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </div>
            <DeleteConfirmation showModal={deleteModalshow} confirmModal={handleDelete} hideModal={handleHideDeleteModal} id={deleteId} message="Weet je zeker dat je deze tijd wilt verwijderen?"  />
        </Layout>
    )
}

export default IndexNorenuur