import axios from "axios";
import { FormEvent, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import Layout from "../layout/Layout";
import { Alert } from "react-bootstrap";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";

const CreateUser = () => {
    const authHeader = useAuthHeader();
    const [values, setValues] = useState({
        email: '',
        password: ''
    })

    // handle errors
    const [errorMessage, setErrorMessage] = useState('');

    const navigate = useNavigate();
    const handleSubmit = (event : FormEvent) => {
        event.preventDefault();
        axios.post(process.env.REACT_APP_SERVER_URL + "/api/register", values, { headers: { Authorization: authHeader } })
        .then(res => {
            console.log(res);
            navigate("/user");
        })
        .catch(err => {
            console.log(err);
            setErrorMessage(err.response.data.message);
        });
    };

    return (
        <Layout>
            <div>
                <h1>Gebruiker toevoegen</h1>
                {errorMessage && (
                    <Alert variant="danger"> {errorMessage} </Alert>
                )}
                <form onSubmit={handleSubmit}>
                    <div className="mb-2">
                        <label htmlFor="datumtijd">E-mailadres: </label>
                        <input type="text" name="email" className="form-control" onChange={e => setValues({...values, email: e.target.value})}/>
                    </div>
                    <div className="mb-2">
                        <label htmlFor="duur">Wachtwoord: </label>
                        <input type="password" name="password" className="form-control" onChange={e => setValues({...values, password: e.target.value})}/>
                    </div>
                    <button type="submit" className="btn btn-success mt-2">Toevoegen</button>
                    <Link to="/user" className="btn btn-primary ms-3 mt-2">Terug</Link>
                </form>
            </div>
        </Layout>
    )
}

export default CreateUser