import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './components/openingstijd/Home';
import Create from './components/openingstijd/Create';
import Update from './components/openingstijd/Update';
import 'bootstrap/dist/css/bootstrap.min.css';
import Generator from './components/openingstijd/Generator';
import AuthProvider from 'react-auth-kit';
import store from './config/auth/authStore';
import AuthOutlet from '@auth-kit/react-router/AuthOutlet';
import Login from './components/Login';
import AxiosNavigation from './config/axios/UseAxiosNavigation';
import IndexDweilpauze from './components/dweilpauze/IndexDweilpauze';
import GeneratorDweilpauze from './components/dweilpauze/GeneratorDweilpauze';
import CreateDweilpauze from './components/dweilpauze/CreateDweilpauze';
import UpdateDweilpauze from './components/dweilpauze/UpdateDweilpauze';
import Dashboard from './Dashboard';
import IndexUser from './components/user/IndexUser';
import CreateUser from './components/user/CreateUser';
import UpdateUser from './components/user/UpdateUser';
import IndexNorenuur from './components/norenuur/IndexNorenuur';
import GeneratorNorenuur from './components/norenuur/GeneratorNorenuur';
import CreateNorenuur from './components/norenuur/CreateNorenuur';
import UpdateNorenuur from './components/norenuur/UpdateNorenuur';
import IndexLocatie from './components/locatie/IndexLocatie';
import CreateLocatie from './components/locatie/CreateLocatie';
import UpdateLocatie from './components/locatie/UpdateLocatie';
import IndexBaantijd from './components/baantijd/IndexBaantijd';
import GeneratorBaantijd from './components/baantijd/GeneratorBaantijd';
import CreateBaantijd from './components/baantijd/CreateBaantijd';
import UpdateBaantijd from './components/baantijd/UpdateBaantijd';

function App() {
  return (
    <div>
      <AuthProvider store={store}>
        <BrowserRouter>
          <AxiosNavigation />
          <Routes>
            <Route path={'/login' } element={<Login/>}/>
            <Route element={<AuthOutlet fallbackPath='/login' />}>
              <Route path="/" element={<Dashboard/>}/>
              <Route path="/locatie" element={<IndexLocatie/>}/>
              <Route path="/locatie/create" element={<CreateLocatie/>}/>
              <Route path="/locatie/update/:id" element={<UpdateLocatie/>}/>
              <Route path="/user" element={<IndexUser/>}/>
              <Route path="/user/create" element={<CreateUser/>}/>
              <Route path="/user/update/:id" element={<UpdateUser/>}/>
              <Route path="/openingstijd" element={<Home/>}/>
              <Route path="/openingstijd/generator" element={<Generator />} />
              <Route path="/openingstijd/create" element={<Create />} />
              <Route path="/openingstijd/update/:id" element={<Update />} />
              <Route path="/baantijd" element={<IndexBaantijd/>}/>
              <Route path="/baantijd/generator" element={<GeneratorBaantijd />} />
              <Route path="/baantijd/create" element={<CreateBaantijd />} />
              <Route path="/baantijd/update/:id" element={<UpdateBaantijd />} />
              <Route path="/dweilpauze" element={<IndexDweilpauze/>}/>
              <Route path="/dweilpauze/generator" element={<GeneratorDweilpauze />} />
              <Route path="/dweilpauze/create" element={<CreateDweilpauze />} />
              <Route path="/dweilpauze/update/:id" element={<UpdateDweilpauze />} />
              <Route path="/norenuur" element={<IndexNorenuur/>}/>
              <Route path="/norenuur/generator" element={<GeneratorNorenuur />} />
              <Route path="/norenuur/create" element={<CreateNorenuur />} />
              <Route path="/norenuur/update/:id" element={<UpdateNorenuur />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </div>
  );
}

export default App;
