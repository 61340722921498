import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Logout } from './Logout';

function Navigation() {
  return (
      <Navbar bg="dark" variant="dark" expand="lg">
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand href="#">Tijden App</Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <LinkContainer to="/locatie">
                <Nav.Link href="#">Locaties</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/openingstijd">
                <Nav.Link href="#">Openingstijden</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/baantijd">
                <Nav.Link href="#">Baantijden</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/dweilpauze">
                <Nav.Link href="#">Dweilpauzes</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/norenuur">
                <Nav.Link href="#">Norenuurtjes</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/user">
                <Nav.Link href="#">Gebruikers</Nav.Link>
              </LinkContainer>
            </Nav>
            <Nav className='logoutLink'>
              <Logout />
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
  );
}

export default Navigation