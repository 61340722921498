import axios from "axios";
import moment from 'moment'
import { FormEvent, useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import Layout from "../layout/Layout";
import { Alert } from "react-bootstrap";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";

type Locatie = {
    id: number,
    naam: string
}

const CreateDweilpauze = () => {
    const authHeader = useAuthHeader();
    let today = moment().format('YYYY-MM-DDTHH:mm');
    const [values, setValues] = useState({
        locatieId: '',
        datumtijd: today,
        duur: ""
    })
    const [locaties, setLocaties] = useState<Locatie[]>([]);

    // handle errors
    const [errorMessage, setErrorMessage] = useState('');

    const navigate = useNavigate();
    const handleSubmit = (event : FormEvent) => {
        event.preventDefault();
        axios.post(process.env.REACT_APP_SERVER_URL + "/api/dweilpauze", values, { headers: { Authorization: authHeader } })
        .then(res => {
            console.log(res);
            navigate("/dweilpauze");
        })
        .catch(err => {
            console.log(err);
            setErrorMessage(err.response.data.message);
        });
    };

    useEffect(() => {
        axios.get(process.env.REACT_APP_SERVER_URL + "/api/locatie", { headers: { Authorization: authHeader } })
            .then(res => setLocaties(res.data))
            .catch(err => { 
                console.log(err);
                setErrorMessage(err.response.data.message);
            });
    }, []);

    return (
        <Layout>
            <div>
                <h1>Dweilpauze toevoegen</h1>
                {errorMessage && (
                    <Alert variant="danger"> {errorMessage} </Alert>
                )}
                <form onSubmit={handleSubmit}>
                    <div className="mb-2">
                        <label htmlFor="locatie">Locatie: </label>
                        <select 
                            name="locatie" 
                            className="form-control" 
                            value={values.locatieId}
                            onChange={e => setValues({...values, locatieId: e.target.value})}
                            required
                        >
                            <option value="" disabled>Kies een locatie</option>
                            {locaties.map(locatie => (
                                <option key={locatie.id} value={locatie.id}>{locatie.naam}</option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-2">
                        <label htmlFor="datumtijd">Datum en tijd: </label>
                        <input type="datetime-local" name="datumtijd" className="form-control" defaultValue={today} onChange={e => setValues({...values, datumtijd: e.target.value})}/>
                    </div>
                    <div className="mb-2">
                        <label htmlFor="duur">Duur van dweilpauze (in minuten): </label>
                        <input type="number" name="duur" className="form-control" onChange={e => setValues({...values, duur: e.target.value})}/>
                    </div>
                    <button type="submit" className="btn btn-success mt-2">Opslaan</button>
                    <Link to="/dweilpauze" className="btn btn-primary ms-3 mt-2">Terug</Link>
                </form>
            </div>
        </Layout>
    )
}

export default CreateDweilpauze