import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import Layout from '../layout/Layout';
import { useNavigate } from 'react-router-dom';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { v4 as uuidv4 } from "uuid";
import axiosInstance from '../../config/axios/AxiosInstance';
import axios from 'axios';

type Locatie = {
  id: number,
  naam: string
}


const GeneratorNorenuur = () => {
  const authHeader = useAuthHeader();
    const [selectedWeekdays, setSelectedWeekdays] = useState<string[]>([]);
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [duur, setDuur] = useState<number>(30);
    const [locatieId, setLocatieId] = useState<string>('');
    const [locaties, setLocaties] = useState<Locatie[]>([]);

    useEffect(() => {
      axios.get(process.env.REACT_APP_SERVER_URL + "/api/locatie", { headers: { Authorization: authHeader } })
          .then(res => setLocaties(res.data))
          .catch(err => { 
              console.log(err);
          });
  }, []);

    const [tijden, setTijden] = useState([
        {
          id: uuidv4(),
          norenuurtje: ''
        },
    ])

    const addTijdRow = () => {
      let _newRow = [...tijden]
      _newRow.push({
        id: uuidv4(),
        norenuurtje: ""
      })
      setTijden(_newRow)
    }

    const deleteTijdRow = (id: string) => {
      let _tijden = [...tijden]
      _tijden = _tijden.filter((tijd) => tijd.id !== id)
      setTijden(_tijden)
    }

    const handleTijdenChange = (
      id: string,
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const index = tijden.findIndex(m => m.id === id)
      let _tijden = [...tijden] as any
      _tijden[index][event.target.name] = event.target.value
      setTijden(_tijden)
    }

    const handleWeekdayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const weekday = e.target.value;
      if (selectedWeekdays.includes(weekday)) {
        setSelectedWeekdays(selectedWeekdays.filter(day => day !== weekday));
      } else {
        setSelectedWeekdays([...selectedWeekdays, weekday]);
      }
    };
  
    const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setStartDate(e.target.value);
      };
    
    const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setEndDate(e.target.value);
    };

    const handleDuurChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setDuur(parseInt(e.target.value));
    };

    const navigate = useNavigate();
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const norenuurtjes = Array.from(tijden.flatMap(x => x.norenuurtje));
      const data = {
        locatieId: locatieId,
        startdatum: startDate,
        einddatum: endDate,
        norenuurtjes: norenuurtjes,
        weekdagen: selectedWeekdays,
        duur: duur
      }

      axiosInstance.post("/api/norenuur/generator", data, { headers: { Authorization: authHeader } })
        .then(res => {
            console.log(res);
            navigate("/norenuur");
        })
        .catch(err => console.log(err));
    };
  
  return (
    <Layout>
      <h2>Norenuurtjes genereren</h2>
      <Form onSubmit={handleSubmit}>
      <Form.Group controlId="startDate">
          <div className="mb-2">
              <label htmlFor="locatieId">Locatie: </label>
              <select 
                  name="locatieId" 
                  className="form-control" 
                  value={locatieId}
                  onChange={e => setLocatieId(e.target.value)}
                  required
              >
                  <option value="" disabled>Kies een locatie</option>
                  {locaties.map(locatie => (
                      <option key={locatie.id} value={locatie.id}>{locatie.naam}</option>
                  ))}
              </select>
          </div>
          <Form.Label>Startdatum generator:</Form.Label>
          <Form.Control
            type="date"
            value={startDate}
            onChange={handleStartDateChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="endDate" className='mt-2'>
          <Form.Label>Einddatum generator:</Form.Label>
          <Form.Control
            type="date"
            value={endDate}
            onChange={handleEndDateChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="norenUurtjes" className='mt-2'>
          <Form.Label>Norenuurtjes:</Form.Label>
          {tijden.map((tijd) => (
              <div className='row' key={tijd.id}>
                <div className='col'>
                  <input
                    className='form-control m-1'
                    name='norenuurtje'
                    type="time"
                    onChange={event => handleTijdenChange(tijd.id, event)}
                    required
                  />
                </div>
                <div className='col'>
                {tijden.length > 1 && (
                    <button type='button' className='btn btn-danger btn-sm m-1' onClick={() => deleteTijdRow(tijd.id)}><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></button>
                  )}
                </div>
              </div>
          ))}
          <button type='button' className='btn btn-info mt-2 mb-2' onClick={addTijdRow}><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon> Extra norenuurtje</button>
        </Form.Group>
        <Form.Group controlId="duur" className='mt-2'>
          <Form.Label>Duur:</Form.Label>
          <Form.Control
            type="number"
            value={duur}
            onChange={handleDuurChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="weekdayCheckboxes" className='mt-2'>
          <Form.Label>Selecteer de weekdagen waarop de tijden gegenereerd moeten worden:</Form.Label>
          <div>
            <Form.Check 
              type="checkbox"
              label="Maandag"
              value="1"
              checked={selectedWeekdays.includes('1')}
              onChange={handleWeekdayChange}
            />
            <Form.Check 
              type="checkbox"
              label="Dinsdag"
              value="2"
              checked={selectedWeekdays.includes('2')}
              onChange={handleWeekdayChange}
            />
            <Form.Check 
              type="checkbox"
              label="Woensdag"
              value="3"
              checked={selectedWeekdays.includes('3')}
              onChange={handleWeekdayChange}
            />
            <Form.Check 
              type="checkbox"
              label="Donderdag"
              value="4"
              checked={selectedWeekdays.includes('4')}
              onChange={handleWeekdayChange}
            />
            <Form.Check 
              type="checkbox"
              label="Vrijdag"
              value="5"
              checked={selectedWeekdays.includes('5')}
              onChange={handleWeekdayChange}
            />
            <Form.Check 
              type="checkbox"
              label="Zaterdag"
              value="6"
              checked={selectedWeekdays.includes('6')}
              onChange={handleWeekdayChange}
            />
            <Form.Check 
              type="checkbox"
              label="Zondag"
              value="7"
              checked={selectedWeekdays.includes('7')}
              onChange={handleWeekdayChange}
            />
          </div>
        </Form.Group>
        <Button variant="primary" type="submit" className='mt-2'>Tijden genereren</Button>
      </Form>
    </Layout>
  );
}

export default GeneratorNorenuur;
