import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import Layout from '../layout/Layout';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';

type Locatie = {
  id: number,
  naam: string
}

function GeneratorBaantijd() {
  const authHeader = useAuthHeader();
    const [selectedWeekdays, setSelectedWeekdays] = useState<string[]>([]);
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [startTime, setStartTime] = useState<string>('');
    const [endTime, setEndTime] = useState<string>('');
    const [locatieId, setLocatieId] = useState<string>('');
    const [baan, setBaan] = useState<string>('');
    const [locaties, setLocaties] = useState<Locatie[]>([]);

    useEffect(() => {
      axios.get(process.env.REACT_APP_SERVER_URL + "/api/locatie", { headers: { Authorization: authHeader } })
          .then(res => setLocaties(res.data))
          .catch(err => { 
              console.log(err);
          });
    }, []);

    const handleWeekdayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const weekday = e.target.value;
      if (selectedWeekdays.includes(weekday)) {
        setSelectedWeekdays(selectedWeekdays.filter(day => day !== weekday));
      } else {
        setSelectedWeekdays([...selectedWeekdays, weekday]);
      }
    };
  
    const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setStartDate(e.target.value);
      };
    
    const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value);
    };

    const handleStartTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartTime(e.target.value);
    };

    const handleEndTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndTime(e.target.value);
    };

    const navigate = useNavigate();
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const data = {
        locatieId: locatieId,
        baan: baan,
        startdatum: startDate,
        einddatum: endDate,
        starttijd: startTime,
        eindtijd: endTime,
        weekdagen: selectedWeekdays
      }

      axios.post(process.env.REACT_APP_SERVER_URL + "/api/baantijd/generator", data, { headers: { Authorization: authHeader } })
        .then(res => {
            console.log(res);
            navigate("/baantijd");
        })
        .catch(err => console.log(err));
    };
  
  return (
    <Layout>
      <h2>Openingstijden genereren</h2>
      <Form onSubmit={handleSubmit}>
        <div className="mb-2">
            <label htmlFor="locatie">Locatie: </label>
            <select 
                name="locatie" 
                className="form-control" 
                value={locatieId}
                onChange={e => setLocatieId(e.target.value)}
                required
            >
                <option value="" disabled>Kies een locatie</option>
                {locaties.map(locatie => (
                    <option key={locatie.id} value={locatie.id}>{locatie.naam}</option>
                ))}
            </select>
        </div>
        <div className="mb-2">
            <label htmlFor="baan">Baan: </label>
            <select 
                name="baan"
                className="form-control" 
                value={baan}
                onChange={e => setBaan(e.target.value)}
                required
            >
                <option value="" disabled>Kies een baan</option>   
                <option value="400meterRink">400m baan</option>
                <option value="curlingRink">Curlingbaan</option>
                <option value="funFreeSkatingRink">Funbaan</option>
            </select>
        </div>
        <Form.Group controlId="startDate">
          <Form.Label>Startdatum generator:</Form.Label>
          <Form.Control
            type="date"
            value={startDate}
            onChange={handleStartDateChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="endDate">
          <Form.Label>Einddatum generator:</Form.Label>
          <Form.Control
            type="date"
            value={endDate}
            onChange={handleEndDateChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="startTime">
          <Form.Label>Openingstijd:</Form.Label>
          <Form.Control
            type="time"
            value={startTime}
            onChange={handleStartTimeChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="endTime" className='mb-2'>
          <Form.Label>Sluitingstijd:</Form.Label>
          <Form.Control
            type="time"
            value={endTime}
            onChange={handleEndTimeChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="weekdayCheckboxes">
          <Form.Label>Selecteer de weekdagen waarop de tijden gegenereerd moeten worden:</Form.Label>
          <div>
            <Form.Check 
              type="checkbox"
              label="Maandag"
              value="1"
              checked={selectedWeekdays.includes('1')}
              onChange={handleWeekdayChange}
            />
            <Form.Check 
              type="checkbox"
              label="Dinsdag"
              value="2"
              checked={selectedWeekdays.includes('2')}
              onChange={handleWeekdayChange}
            />
            <Form.Check 
              type="checkbox"
              label="Woensdag"
              value="3"
              checked={selectedWeekdays.includes('3')}
              onChange={handleWeekdayChange}
            />
            <Form.Check 
              type="checkbox"
              label="Donderdag"
              value="4"
              checked={selectedWeekdays.includes('4')}
              onChange={handleWeekdayChange}
            />
            <Form.Check 
              type="checkbox"
              label="Vrijdag"
              value="5"
              checked={selectedWeekdays.includes('5')}
              onChange={handleWeekdayChange}
            />
            <Form.Check 
              type="checkbox"
              label="Zaterdag"
              value="6"
              checked={selectedWeekdays.includes('6')}
              onChange={handleWeekdayChange}
            />
            <Form.Check 
              type="checkbox"
              label="Zondag"
              value="7"
              checked={selectedWeekdays.includes('7')}
              onChange={handleWeekdayChange}
            />
          </div>
        </Form.Group>
        <Button variant="primary" type="submit" className='mt-2'>Tijden genereren</Button>
      </Form>
    </Layout>
  );
}

export default GeneratorBaantijd;