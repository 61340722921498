import React from 'react';
import { Container } from 'react-bootstrap';
import Navigation from './Navigation';
import "../../content/layout/index.css";

type Props = {
  children: React.ReactNode,
};

const Layout : React.FC<Props> = ({
  children
}) => (
    <div>
      <Navigation />
      <Container className="mt-4">
        {children}
      </Container>  
    </div>
  );

export default Layout;