import axios from "axios";
import { FormEvent, useEffect, useState } from "react";
import { Link, useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import Layout from "../layout/Layout";
import { Alert } from "react-bootstrap";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";

const UpdateDweilpauze = () => {
    const authHeader = useAuthHeader();
    const { id } = useParams();

    const [values, setValues] = useState({
        datumtijd: '',
        duur: ''
    })

    // handle errors
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        axios.get(process.env.REACT_APP_SERVER_URL + "/api/dweilpauze/" + id, { headers: { Authorization: authHeader } })
            .then(res => setValues(res.data))
            .catch(err => { 
                console.log(err);
                setErrorMessage(err.response.data.message);
            });
    }, [authHeader, id])

    const navigate = useNavigate();
    const handleUpdate = (event : FormEvent) => {
        event.preventDefault();
        axios.put(process.env.REACT_APP_SERVER_URL + "/api/dweilpauze/" + id, values, { headers: { Authorization: authHeader } })
        .then(res => {
            console.log(res);
            navigate("/dweilpauze");
        })
        .catch(err => {
            console.log(err);
            setErrorMessage(err.response.data.message);
        });
    };

    return (
        <Layout>
            <div>
                <h1>Dweilschema bewerken</h1>
                {errorMessage && (
                    <Alert variant="danger"> {errorMessage} </Alert>
                )}
                <form onSubmit={handleUpdate}>
                    <div className="mb-2">
                        <label htmlFor="datumtijd">Datum en tijd: </label>
                        <input type="datetime-local" name="datumtijd" className="form-control" value={moment(values.datumtijd).format('YYYY-MM-DDTHH:mm')} onChange={e => setValues({...values, datumtijd: e.target.value})}/>
                    </div>
                    <div className="mb-2">
                        <label htmlFor="duur">Duur: </label>
                        <input type="number" name="duur" className="form-control" value={values.duur} onChange={e => setValues({...values, duur: e.target.value})}/>
                    </div>
                    <button type="submit" className="btn btn-success mt-2">Opslaan</button>
                    <Link to="/dweilpauze" className="btn btn-primary ms-3 mt-2">Terug</Link>
                </form>
            </div>
        </Layout>
    )
}

export default UpdateDweilpauze